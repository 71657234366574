.showontop {
  z-index: 999;
}

.digitalcardcontainer {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  vertical-align: middle;
  align-items: center;

  #digitalcard {
    position: absolute;
    margin: auto;
    display: block;

    #logocontainer {
      display: block;
      border-bottom: thin solid #666666;

      .logo {
        height: 100%;
      }
    }

    #photocontainer {
      position: absolute;
      display: block;

      .photo {
        display: block;
        height: 100%;
      }
    }

    #datacontainer {
      position: absolute;
      display: block;

      div {
        display: none;
      }

      .name {
        display: block;
        position: absolute;
        font-weight: 700;
        color: #003b76;

        .firstname, .middlename, .lastname {
          display: inline;
        }

        .firstname::after, .middlename::after {
          content: ' ';
        }
      }

      .group {
        display: block;
        position: absolute;
        font-weight: 700;
        color: #eab91f;
      }

      .profilenumber, .cardnumber, .enddate {
        display: block;
        position: absolute;
      }

      .cardnumber::before {
        content: 'Kaartnr.: ';
      }

      .enddate::before {
        content: 'Geldig tot: ';
      }

    }

    #barcodecontainer {
      position: absolute;
      display: block;
      text-align: center;

      .barcodediv {
        display: block;

        svg {
          display: block;
          width: 100%;
          height: inherit;

          g {
            display: block;
            width: 100%;
            height: inherit;

            rect {
              display: inherit;
            }
          }
        }
      }

      .valuediv {
        display: block;
        width: 100%;
      }
    }

    #qrcodecontainer {
      position: absolute;
      display: block;
    }
  }

  .student .profilenumber::before {
    content: 'Studentnr.: ';
  }

  .medewerker .profilenumber::before {
    content: 'Medewerkernr.: ';
  }

}


.digitalcardcontainer.desktop {
  #digitalcard {
    width:   calc(min(100vw, 200vh)*0.6);
    height:  calc(min( 50vw, 100vh)*0.6);
  }

  #logocontainer {
    width:   calc(min(100vw, 200vh)*0.6);
    height:  calc(min( 10vw,  20vh)*0.6);
    padding: calc(min(  1vw,   2vh)*0.6);
  }

  #datacontainer {
    width:   calc(min( 40vw,  80vh)*0.6);
    height:  calc(min( 40vw,  80vh)*0.6);
    left:    calc(min( 32vw,  64vh)*0.6);
    padding: calc(min(  1vw,   2vh)*0.6);
    bottom:  0;

    .name, .group, .profilenumber, .cardnumber, .enddate {
      left:      calc(min(  1vw,  2vh)*0.6);
    }

    .name {
      font-size: calc(min(  3vw,   6vh)*0.6);
      top:       calc(min( 12vw,  24vh)*0.6);
    }

    .group {
      font-size: calc(min(2.5vw,   5vh)*0.6);
      top:       calc(min( 16vw,  32vh)*0.6);
    }

    .profilenumber, .cardnumber, .enddate {
      font-size: calc(min(  2vw,   4vh)*0.6);
    }

    .profilenumber {
      top:       calc(min( 21vw,  42vh)*0.6);
    }

    .cardnumber {
      top:       calc(min( 24vw,  48vh)*0.6);
    }

    .enddate {
      top:       calc(min( 27vw,  54vh)*0.6);
    }
  }

  #photocontainer {
    width:   calc(min( 30vw,  60vh)*0.6);
    height:  calc(min( 40vw,  80vh)*0.6);
    padding: calc(min(  1vw,   2vh)*0.6);
  }

  #qrcodecontainer {
    width:   calc(min( 30vw,  60vh)*0.6);
    height:  calc(min( 60vw,  60vh)*0.6);
    right:   0;
    padding: calc(min(  1vw,   2vh)*0.6);
  }

  #barcodecontainer {
    width:   calc(min( 30vw,  60vh)*0.6);
    height:  calc(min( 10vw,  20vh)*0.6);
    padding: calc(min(  1vw,   2vh)*0.6);
    right: 0;
    bottom: 0;

    .barcodediv {
      height:  calc(min( 6vw,  12vh)*0.6);
    }

    .valuediv {
      font-size: calc(min(  2vw,   4vh)*0.6);
    }
  }
}

.digitalcardcontainer.mobile.landscape {
  #digitalcard {
    width:   min(100vw, 200vh);
    height:  min( 50vw, 100vh);
  }

  #logocontainer {
    width:   min(100vw, 200vh);
    height:  min( 10vw,  20vh);
    padding: min(  1vw,   2vh);
  }

  #datacontainer {
    width:   min( 40vw,  80vh);
    height:  min( 40vw,  80vh);
    left:    min( 32vw,  64vh);
    padding: min(  1vw,   2vh);
    bottom:  0;

    .name, .group, .profilenumber, .cardnumber, .enddate {
      left:      min(  1vw,  2vh);
    }

    .name {
      font-size: min(  3vw,   6vh);
      top:       min( 12vw,  24vh);
    }

    .group {
      font-size: min(2.5vw,   5vh);
      top:       min( 16vw,  32vh);
    }

    .profilenumber, .cardnumber, .enddate {
      font-size: min(  2vw,   4vh);
    }

    .profilenumber {
      top:       min( 21vw,  42vh);
    }

    .cardnumber {
      top:       min( 24vw,  48vh);
    }

    .enddate {
      top:       min( 27vw,  54vh);
    }

  }

  #photocontainer {
    width:   min( 30vw,  60vh);
    height:  min( 40vw,  80vh);
    padding: min(  1vw,   2vh);
  }

  #qrcodecontainer {
    width:   min( 30vw,  60vh);
    height:  min( 60vw,  60vh);
    right:   0;
    padding: min(  1vw,   2vh);
  }

  #barcodecontainer {
    width:   min( 30vw,  60vh);
    height:  min( 10vw,  20vh);
    padding: min(  1vw,   2vh);
    right: 0;
    bottom: 0;

    .barcodediv {
      height:  min( 6vw,  12vh);
    }

    .valuediv {
      font-size: min(  2vw,   4vh);
    }
  }
}

.digitalcardcontainer.mobile.portrait {
  #digitalcard {
    transform: rotate(90deg);
    width:  min(100vh, 200vw);
    height: min(50vh, 100vw);
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  #logocontainer {
    width:   min(100vh, 200vw);
    height:  min( 10vh,  20vw);
    padding: min(  1vh,   2vw);
  }

  #photocontainer {
    width:   min( 30vh,  60vw);
    height:  min( 40vh,  80vw);
    padding: min(  1vh,   2vw);
  }

  #datacontainer {
    width:   min( 40vh,  80vw);
    height:  min( 40vh,  80vw);
    left:    min( 32vh,  64vw);
    padding: min(  1vh,   2vw);
    bottom:  0;

    .name, .group, .profilenumber, .cardnumber, .enddate {
      left:      min(  1vh,  2vw);
    }

    .name {
      font-size: min(  3vh,   6vw);
      top:       min( 12vh,  24vw);
    }

    .group {
      font-size: min(2.5vh,   5vw);
      top:       min( 16vh,  32vw);
    }

    .profilenumber, .cardnumber, .enddate {
      font-size: min(  2vh,   4vw);
    }

    .profilenumber {
      top:       min( 21vh,  42vw);
    }

    .cardnumber {
      top:       min( 24vh,  48vw);
    }

    .enddate {
      top:       min( 27vh,  54vw);
    }

  }

  #qrcodecontainer {
    width:   min( 30vh,  60vw);
    height:  min( 60vh,  60vw);
    right:   0;
    padding: min(  1vh,   2vw);
  }

  #barcodecontainer {
    width:   min( 30vh,  60vw);
    height:  min( 10vh,  20vw);
    padding: min(  1vh,   2vw);
    right: 0;
    bottom: 0;

    .barcodediv {
      height:  min( 6vh,  12vw);
    }

    .valuediv {
      font-size: min(  2vh,   4vw);
    }
  }
}
