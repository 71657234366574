.cardpreview {
  background-image:  url('../images/cardtemplate.png');
  border-radius:     15px;
  font-family:       Athletics, sans-serif;
  font-size:         12px;
  font-weight:       700;
  line-height:       14px;
  color:             #1d4882;
  position:          relative;
  background-size:   contain;
  background-repeat: no-repeat;
  height:            221px;
  width:             340px;

  * {
    display: none;
  }

  .barcode {
    display: block;
    position: absolute;
    top: 121px;
    left: -28px;
    width: 96px;
    rotate: 90deg;
    //line-height: 25px;

    .barcodediv {
      display: block;
      height: 25px;

      svg {
        display: block;
        width: 100%;
        height: inherit;

        g {
          display: block;
          width: 100%;
          height: inherit;

          rect {
            display: inherit;
          }
        }
      }
    }

    .valuediv {
      display: none;
    }

    z-index: 999;
  }

  .photo {
    display: block;
    position: absolute;
    top:  85px;
    left: 36px;
    width: 90px;
    height: 120px;
  }

  .name::before {
    content: 'Naam:';
    display: block;
    font-weight: 900;
  }

  .name {
    display: block;
    position: absolute;
    top: 84px;
    left: 130px;

    * {
      display: none;
    }

    .nameoncard {
      display: block;
    }
  }

  .profilenumber::before {
    content: 'Nummer:';
    display: block;
    font-weight: 900;
  }

  .profilenumber {
    display: block;
    position: absolute;
    top: 112px;
    left: 130px;
  }

  .cardnumber::before {
    content: 'Kaartnummer:';
    display: block;
    font-weight: 900;
  }

  .cardnumber {
    display: block;
    position: absolute;
    top: 140px;
    left: 130px;
  }

  .startdate::before {
    content: 'Geldig vanaf:';
    display: block;
    font-weight: 900;
  }

  .startdate {
    display: block;
    position: absolute;
    top: 168px;
    left: 130px;
  }

  .group {
    display: block;
    position: absolute;
    text-align: right;;
    font-size: 16px;
    font-weight: 900;
    top: 194px;
    left: 130px;
    width: 188px;
  }
}

.cardpreview.student {

  .profilenumber::before {
    content: 'Studentnummer:';
  }

  .group::after {
    content: ' card';
  }

}
