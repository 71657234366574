.w3-theme-light {
  background-color: $basecolor3;
  color: white;
}

.w3-theme-l3 {
  background-color: $basecolor2;
  color: $basecolor1;
}

.w3-theme-l4, .w3-theme-l5 {
  background-color: $basecolor1;
  color: $basecolor2;
}

.w3-hover-theme, .w3-dropdown-hover, .w3-dropdown-hover:first-child, .w3-dropdown-hover:hover .w3-button:first-child {
  background-color: $basecolor2;
  border-color: $basecolor2;
  color: $basecolor1;
}

.w3-hover-theme:hover {
  background-color: $basecolor1 !important;
  color: $basecolor2 !important;
}

.w3-border-theme {
  border: 0 !important;
}

.w3-input-theme {
  outline: none;
  color:$basecolor1 !important;
  background-color: $basecolor3 !important;
  border: 1px solid $basecolor2 !important;
  margin: 2px !important;
}

.w3-input-theme:focus,
.w3-input-theme:active {
  outline: none !important;
  border: 2px solid $basecolor2 !important;
  margin: 0 !important;
}

.w3-input-theme:checked {
  color:$basecolor1 !important;
  background-color:$basecolor1 !important;
}

.w3-button-theme {
  color: $basecolor1;
  font-weight: 700;
  background-color: $basecolor2 !important;
  border: 2px solid $basecolor2 !important;
  white-space: pre-line;
}

.w3-button-theme:hover, .w3-button-theme:focus, .w3-button-theme-active {
  outline: none;
  background-color: darken($basecolor2,10) !important;
  border: 2px solid darken($basecolor2,10) !important;
  color: lighten($basecolor1,10) !important;
}

.w3-theme-l4 .link {
  color: lighten($basecolor2,10);
  font-weight: 700;
  cursor: pointer;
}

.w3-theme-l4 .link:hover, .w3-theme-l4 .link:focus, .w3-theme-l4 .link:active  {
  color: lighten($basecolor2,20);
  text-decoration: underline;
}

footer .link {
  color: $basecolor1;
  cursor: pointer;
}

footer .link:hover, footer .link:focus, footer .link:active  {
  text-decoration: underline;
  color: lighten($basecolor1,20);
}

.w3-table {
  color: $basecolor1;
}
