@font-face {
   font-family: Athletics;
   src: url('./Athletics-Regular.otf');
   font-weight: 400;
}

@font-face {
   font-family: Athletics;
   src: url('./Athletics-Italic.otf');
   font-style: italic;
   font-weight: 400;
}

@font-face {
   font-family: Athletics;
   src: url('./Athletics-Bold.otf');
   font-weight: 700;
}

@font-face {
   font-family: Athletics;
   src: url('./Athletics-BoldItalic.otf');
   font-style: italic;
   font-weight: 700;
}

@font-face {
   font-family: Athletics;
   src: url('./Athletics-ExtraBold.otf');
   font-weight: 900;
}
